<template>
  <div>
    <v-img
      height="175"
      contain
      v-if="item.imageUrl"
      :src="item.imageUrl"
      :lazy-src="item.imageUrl"
      aspect-ratio="1"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div v-else>
      <v-img
        class="pointer"
        height="175"
        @click="fatchImage(item)"
        contain
        src="@/assets/images/photoComingSoon.png"
      />
      <span>Click here to fatch image</span>
    </div>

    <!-- <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn  :loading="loading" icon fab v-on="on" @click="fatchImage(item)">
          <v-img height="175"
            contain
            src="@/assets/images/photoComingSoon.png"
          />
        </v-btn>
      </template>
      <span>Fatch Image</span>
    </v-tooltip> -->
  </div>
</template>

<script>
import productService from "@/modules/Product/service.js";
import categoryService from "@/modules/Product/Category/service.js";

export default {
  props: ["item", "type"],
  data() {
    return {
      loading: false,
      imageResult: null,
      imageUrl: null,
    };
  },
  methods: {
    getimage(name) {
      return productService
        .getGooglePic({ name: name })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateProduct(item, imageUrl) {
      return productService
        .update(item.id, { imageUrl })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateCategory(item, imageUrl) {
      return categoryService
        .update(item.id, { imageUrl })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    fatchImage() {
      this.loading = true;
      this.getimage(this.item.name)
        .then((response) => {
          if (response.data) {
            this.imageResult = response.data;
            this.imageUrl = response.data.imgUrl1;
            this.item.imageUrl = this.imageUrl;
            if (this.type === "product") {
              this.updateProduct(this.item, this.imageUrl);
              this.loading = false;
            }
            if (this.type === "category") {
              this.updateCategory(this.item, this.imageUrl);
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
