import backofficeService from '../../../api/backoffice'
// import publicService from '../../../api/public'


export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  async getAll(params) {
    return backofficeService.Api().get('product', { params })
      .then(response => {
        // console.log('in here')
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  
  saveItem(data) {
    return backofficeService.Api().post("/CatalogOrder", data)
      .then(response => {
        return response.data
      })
      .catch((error) => { throw error })
  },

  getOrderByCustomerId(id) {
    return backofficeService.Api().get(`/CatalogOrder/customer/${id}`)
      .then(response => {
        return response.data
      })
      .catch((error) => { throw error })
  },

}
