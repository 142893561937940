<template>
  <v-card outlined hover @click="submit">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-img
            contain
            v-if="category.imageUrl"
            height="160px"
            :src="category.imageUrl"
          >
          </v-img>
          <v-img
            contain
            v-else
            height="160px"
            src="@/assets/images/nophoto.png"
          >
          </v-img>
        </v-col>
        <v-col>
          <span class="title">{{ category.name }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['category'],
  methods: {
    submit() {
      this.$emit('submit')
    }
  }
};
</script>

<style scoped></style>
