<template>
  <v-card outlined>
    <v-col align="center">
      <OrderImageView :item="product" type="product" />
    </v-col>
    <v-card-text>
      <div>{{ product.name }}</div>
      <div class="font-weight-bold">
        {{ product.price | currency }}
      </div>
    </v-card-text>
    <v-divider> </v-divider>
    <div class="text-center">
      <v-chip class="ma-2 px-5" @click="replaceQty(2)">2</v-chip>
      <v-chip class="ma-2 px-5" @click="replaceQty(5)">5</v-chip>
      <v-chip class="ma-2 px-5" @click="replaceQty(8)">8</v-chip>
      <v-chip class="ma-2 px-5" @click="replaceQty(10)">10</v-chip>
      <v-chip class="ma-2 px-5" @click="replaceQty(12)">12</v-chip>
    </div>
    <v-card-actions>
      <v-row align="center" justify="space-between">
        <v-col class="shrink">
          <v-btn @click="minusQty(product)" fab color="error">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-text-field
            class="centered-input display-1 font-weight-bold"
            v-model="product.qty"
            hide-details
            single-line
            readonly
            height="50"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col class="shrink">
          <v-btn @click="addQty(product)" fab color="success">
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import OrderImageView from "@/components/Image/OrderImageView.vue";
import { mapActions } from "vuex";

export default {
  props: ["product"],
  name: "product-card",
  components: {
    OrderImageView,
  },
  methods: {
    ...mapActions("catalog", ["addOrderDetail", "minusOrderDetail"]),
    addQty() {
      this.$emit("addQty", this.product);
    },
    minusQty() {
      this.$emit("minusQty", this.product);
    },
    replaceQty(qty) {
      // console.log('qty', qty)
      this.$emit("replaceQty", this.product, qty);
    },
  },
};
</script>

<style scoped></style>
