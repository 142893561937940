<template>
  <div>
    <v-app-bar app clipped-left color="#F0C316">
      <v-app-bar-nav-icon dark @click="home">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title white--text text-capitalize">Category</span>
      </v-toolbar-title>

      <v-spacer />
      <span>{{ total | currency }}</span>

      <v-btn
        fab
        class="ml-1 mr-2"
        @click="viewOrder = true"
        icon
        v-if="cart.length"
      >
        <v-badge
          :content="cart.length"
          :value="cart.length"
          color="green"
          overlap
        >
          <v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card outlined>
              <v-toolbar dense flat color="grey lighten-3">
                <v-toolbar-title>Create Order </v-toolbar-title>
                <v-spacer> </v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-row class="pl-2" justify="space-between">
                  <v-col cols="12" sm="12" md="4">
                    <AutoComplete
                      v-model="selectedCustomer"
                      :errorMessages="selectCustomerError"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <SalesMan v-model="selectedUser" :initValue="initUser" />
                  </v-col>
                  <v-col sm="6" md="2">
                    <DateSelector label="Date" v-model="Order.date" />
                  </v-col>
                  <v-col sm="6" md="2">
                    <v-dialog v-model="orderNote" width="500">
                      <template v-slot:activator="{ on }">
                        <v-btn outlined v-on="on">Order Note</v-btn>
                      </template>

                      <v-card>
                        <ModelTitle
                          title="Order Note"
                          @close="orderNote = !orderNote"
                        />

                        <v-card-text class="mt-3">
                          <v-textarea
                            outlined
                            dense
                            hide-details
                            v-model="Order.note"
                            label="Order Note"
                          ></v-textarea>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="orderNote = false"
                            >Close</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col
                sm="12"
                md="3"
                v-for="(category, index) in Categorys"
                :key="index"
              >
                <Category :category="category" @submit="getProduct(category)" />
              </v-col>
            </v-row>
            <v-footer app class="pa-2">
              <v-btn
                color="success"
                :disabled="cart.length > 0 ? false : true"
                text
                block
                @click="saveOrder"
                >{{ total | currency }} Save Order</v-btn
              >
            </v-footer>
          </v-window-item>
          <v-window-item :value="2">
            <v-row v-if="Products.length !== 0">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="(product, index) in Products"
                :key="index"
              >
                <Product
                  :product="product"
                  @replaceQty="replaceQty"
                  @minusQty="minusQty"
                  @addQty="addQty"
                />
              </v-col>
            </v-row>
            <v-footer app class="pa-3">
              <v-btn color="info" text block @click="step = 1"
                >Back To Category</v-btn
              >
            </v-footer>
          </v-window-item>
        </v-window>
      </v-container>
    </v-main>

    <div class="text-center">
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="primary" class="pa-3" dark>
          <v-card-text>
            {{ title }}
            <v-progress-linear
              color="yellow"
              class="mt-2"
              height="10"
              value="10"
              indeterminate
              striped
            >
            </v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog
      v-model="viewOrder"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar dark color="teal">
          <v-btn icon dark @click="viewOrder = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cart</v-toolbar-title>
          <v-spacer />
          <span class="font-weight-bold">Total: {{ total | currency }}</span>
        </v-toolbar>
        <v-list>
          <div v-for="(product, index) in cart" :key="index">
            <v-list-item>
              <v-list-item-content>
                <v-card flat width="100%">
                  <v-row>
                    <v-col sm="12" md="8">
                      <v-list-item>
                        <v-list-item-avatar
                          v-if="product.imageUrl"
                          color="grey"
                        >
                          <v-img
                            contain
                            v-if="product.imageUrl"
                            :src="product.imageUrl"
                          >
                          </v-img>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-else color="grey">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="headline">
                            {{ product.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            product.Category.name
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col
                      md="4"
                      class="text-right"
                      v-if="!$vuetify.breakpoint.xsOnly"
                    >
                      <span class="headline">{{
                        product.total | currency
                      }}</span>
                    </v-col>
                    <v-col sm="12" class="text-right" v-else>
                      <span class="headline">{{
                        product.total | currency
                      }}</span>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center" justify="space-between">
                        <v-col class="shrink">
                          <v-btn @click="minusQty(product)" fab color="error">
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-text-field
                            class="centered-input display-1 font-weight-bold"
                            v-model="product.qty"
                            hide-details
                            single-line
                            readonly
                            height="50"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="shrink">
                          <v-btn @click="addQty(product)" fab color="success">
                            <v-icon>add</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list-item-content>
            </v-list-item>
            <v-divider> </v-divider>
          </div>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const { validationMixin } = require("vuelidate");
const { required, minValue } = require("vuelidate/lib/validators");
import DateSelector from "@/components/DateSelector";
import Category from "@/modules/Order/Catalog/components/Category.vue";
import Product from "@/modules/Order/Catalog/components/Product.vue";
import SalesMan from "@/modules/User/components/AutoComplete.vue";
import AutoComplete from "@/modules/Customer/components/AutoComplete";
import categoryService from "@/modules/Product/Category/service";
import catalogOrderService from "@/modules/Order/Catalog/service.js";
import productService from "../service";
import orderService from "@/modules/Order/InHouse/service.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "create-order",
  data() {
    return {
      Categorys: [],
      viewOrder: false,
      loading: false,
      total: 0,
      Products: [],
      title: "Please stand by",
      step: 1,
      selectedCategory: null,
      orderNote: false,
      selectedCustomer: null,
      selectedUser: null,
      Customer: [],
      cart: [],
      Order: {
        date: this.$moment().toISOString(),
        note: null,
        CustomerId: null,
        OrderDetails: [],
      },
    };
  },
  components: {
    DateSelector,
    AutoComplete,
    Product,
    Category,
    SalesMan,
  },
  mixins: [validationMixin],
  validations: {
    Order: {
      CustomerId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  created() {
    this.getCategoryList();
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    totalQty() {
      let total = 0;
      if (this.Order.OrderDetails) {
        this.Order.OrderDetails.map((row) => {
          total += parseFloat(row.qty);
        });
      }
      return total;
    },
    selectCustomerError() {
      const errors = [];
      if (!this.$v.Order.CustomerId.$dirty) return errors;
      if (!this.$v.Order.CustomerId.required)
        errors.push("Pleaes select customer");
      if (!this.$v.Order.CustomerId.minValue)
        errors.push("Pleaes select customer");
      return errors;
    },
    invoiceTotal() {
      let total = 0;
      this.Order.OrderDetails.map((row) => {
        total += row.qty * row.price;
      });
      return total;
    },
  },
  watch: {
    cart: {
      handler(val) {
        this.total = 0;
        val.map((row) => {
          row.total = row.price * row.qty;
          this.total += row.total;
        });
      },
      deep: true,
    },
    // cart(val) {
    //   this.total = 0;
    //   val.map((row) => {
    //     this.total += row.price * row.qty
    //     // console.log('this.total', row.price, row.qty)
    //   });
    // },
    selectedCustomer(val) {
      this.Order.CustomerId = this.selectedCustomer.id;
      if (val) {
        if (val.SalesRep) {
          this.initUser = val.SalesRep;
        } else {
          this.initUser = this.currentUser;
        }
      } else {
        this.initUser = this.currentUser;
      }
    },
  },
  methods: {
    ...mapActions("global", ["initUser"]),
    home() {
      if (this.cart.length > 0) {
        this.$swal({
          title: "Are you sure?",
          text: "Order is not save going home will reset the cart",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this.$router.push({ path: "/order/list" });
          }
        });
      } else {
        this.$router.push({ path: "/order/list" });
      }
    },
    setProductQty() {
      this.cart.map((o) => {
        const i = this.Products.findIndex((p) => p.id === o.id);
        if (i !== -1) this.Products[i].qty = o.qty;
      });
    },
    getOrderByCustomer() {
      catalogOrderService
        .getOrderByCustomerId(this.selectedCustomer.id)
        .then((response) => {
          // console.log("saveItem response", response.data.catalogOrder);
          this.cart = response.data.catalogOrder.CatalogOrderDetails;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateTotal(product) {
      this.total = 0;
      this.cart.map((product) => {
        this.total += product.totalPrice;
      });
      this.setCart(product);
    },
    addQty(product) {
      product.qty++;
      this.updateTotal(product);
    },
    minusQty(product) {
      if (product.qty > 0) {
        product.qty--;
        this.updateTotal(product);
      }
    },
    setCart(product) {
      const i = this.cart.findIndex((p) => p.id === product.id);
      if (i !== -1) {
        this.cart[i].qty = product.qty;
        this.cart[i].totalPrice = product.qty * product.price;
      } else {
        this.cart.push(product);
      }
    },
    replaceQty(product, qty) {
      product.qty = qty;
      this.updateTotal(product);
    },
    saveOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.Order.OrderDetails = this.cart;
        this.Order.SalesRepUserId = this.selectedUser.id;
        if (this.Order.OrderDetails.length > 0) {
          return orderService.create(this.Order).then((result) => {
            // console.log("result", result);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Order was created!",
              });

            this.$router.go(-1);
          });
        }
      }
    },
    async getProduct(item) {
      this.loading = true;
      this.title = `Please getting product for ${item.name}`;
      this.selectedCategory = item;
      await this.getProductByCategory(item);
      this.loading = false;
    },
    getProductByCategory(category) {
      return productService
        .getAll({ CategoryId: category.id })
        .then((response) => {
          this.Products = [];
          response.data.map((row) => {
            this.Products.push({ ...row, qty: 0 });
          });
          this.step = 2;
          this.setProductQty();
          // this.Products = response.data;
        });
    },
    getCategoryList() {
      return categoryService.getAll().then((response) => {
        this.Categorys = response.data;
      });
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.Order.OrderDetails.splice(i, 1);
        }
      });
    },
    addRow(val) {
      // console.log("addrow", val);
      this.Order.OrderDetails.push({
        ProductId: val.id,
        name: val.Bar.name,
        price: val.Bar.price,
        total: val.Bar.price * val.Bar.qty,
        qty: val.Bar.qty,
      });
    },
  },
};
</script>

<style scoped></style>
